import "./includes/form_polyfill";

import Teamshares from "@teamshares/design-system/rails-js";

Teamshares.start();

document.addEventListener(
  "turbo:before-cache",
  () => {
    if (document.querySelector(".g-recaptcha") !== null) {
      document.querySelector(".g-recaptcha").innerHTML = "";
    }
  },
);
