import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submission-log"
export default class extends Controller {
  openEvaluationDetail (e) {
    e.target.classList.add("hidden");
    const dropdownID = e.target.id.replace("open_", "");
    document.getElementById(dropdownID).classList.remove("hidden");
    document.getElementById("close_" + dropdownID).classList.remove("hidden");
  }

  closeEvaluationDetail (e) {
    e.target.classList.add("hidden");
    const dropdownID = e.target.id.replace("close_", "");
    document.getElementById(dropdownID).classList.add("hidden");
    document.getElementById("open_" + dropdownID).classList.remove("hidden");
  }

  closeFromInsideEvaluationDetail (e) {
    const dropdownID = e.target.id.replace("closeInside_", "");
    document.getElementById(dropdownID).classList.add("hidden");
    document.getElementById("open_" + dropdownID).classList.remove("hidden");
    document.getElementById("close_" + dropdownID).classList.add("hidden");
  }
}
