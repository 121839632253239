import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="clickable-dashboard-table-row"
export default class extends Controller {
  static values = { url: String };

  click (e) {
    if (e.target.classList.contains("no-click")) {
      return;
    }

    this.handleClick();
  }

  handleClick () {
    localStorage.setItem("candidateIds", null);
    window.location.href = this.urlValue;
  }
}
