import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs/modular/sortable.complete.esm.js";

// Connects to data-controller="matching-preference-step--mba-placement"
export default class extends Controller {
  static targets = [
    "rankSelectionList",
  ];

  connect () {
    const hiddenLocationField = document.getElementById("matching_preference_location_rank");

    // Initialize sorting functionality with options on controller connect
    Sortable.create(
      document.getElementById("rankSelectionList"),
      {
        animation: 150,
        dragClass: ["bg-gray-500"],
        chosenClass: ["bg-gray-500"],
        // Below is a lifesycle callback exposed by the sortable class that fires when sorting is changed, we'll do three things here:
        // 1. We're going to grab the list
        // 2. Set our hidden field in the form to that list of the text content of each location (city and state)
        // 3. Set the localStorage variable to the list of DOM generated ID's that we use to match to each choice
        onUpdate: function (evt) {
          const generatedRankedListChildNodes = Array.from(evt.target.childNodes);
          hiddenLocationField.setAttribute("value", generatedRankedListChildNodes.map((node) => node.textContent).join("_"));
          localStorage.setItem("rankedList", generatedRankedListChildNodes.map((node) => node.id).toString());
        },
      },
    );

    const isSameCandidate = window.location.pathname.split("/")[2] == localStorage.getItem("cpsId"); // eslint-disable-line

    if (!isSameCandidate) {
      localStorage.setItem("cpsId", window.location.pathname.split("/")[2]);
      localStorage.setItem("rankedList", null);
    }

    // When we navigate back to the matching preference page, we want to reconstruct the rankedList within the DOM based on the choices in localStorage
    if (localStorage.getItem("cpsId") == null) { // eslint-disable-line
      localStorage.setItem("cpsId", window.location.pathname.split("/")[2]);
    }

    if (localStorage.getItem("rankedList") != "" && localStorage.getItem("rankedList") !== "null" && isSameCandidate ) { // eslint-disable-line

      const parsedStoredRankedList = localStorage.getItem("rankedList").split(",");
      const rankSelectionList = document.getElementById("rankSelectionList");

      // Iterate over each id within the parsed localstorage variable and reconstruct the list within the DOM
      parsedStoredRankedList.forEach((val) => {
        const span = document.createElement("span");

        // Next 3 lines are so that we can go outside of the list, target the input that has the corresponding id, and check it in the UI
        const inputChoiceId = val.replace("span_", "");
        const inputEl = document.getElementById(inputChoiceId);
        inputEl.checked = true;

        // Construct the span element to add to the ranked list and add it
        span.append(
          this.generateHandleSVG(),
          document.createTextNode(inputEl.nextSibling.textContent),
        );
        span.classList.add("px-2", "py-1", "bg-gray-200", "rounded-md", "inline-block", "cursor-move", "flex", "items-center");
        span.setAttribute("id", `${val}`);

        rankSelectionList.appendChild(span);
      });

      // Once we've constructed and appended all locations to the ranked list, we refresh the value of the hidden field in the form to make sure it's up to date
      hiddenLocationField.setAttribute("value", Array.from(rankSelectionList.childNodes).map((node) => node.textContent).join("_"));
    }

    localStorage.setItem("cpsId", window.location.pathname.split("/")[2]);
  }

  placeInRankSelectionList (e) {
    const rankSelectionList = document.getElementById("rankSelectionList");
    const hiddenLocationField = document.getElementById("matching_preference_location_rank");

    // This is the list on the client side that is generated on each select/deselect
    // We'll use this variable to keep up with whats in the rankSelectionList
    let generatedRankedList = Array.from(rankSelectionList.childNodes).map((node) => node.id);

    // We add the chosen location to the list when its clicked and if we haven't hit the max allowed chocies yet
    if (e.target.checked) {
      // Construct the span element to be added to the list
      const span = document.createElement("span");
      span.append(
        this.generateHandleSVG(),
        document.createTextNode(e.target.nextSibling.textContent),
      );
      span.classList.add("px-2", "py-1", "bg-gray-200", "rounded-md", "inline-block", "cursor-move", "flex", "items-center");
      span.setAttribute("id", `span_${e.target.id}`);

      // Append the generated span to the rankSelection div
      rankSelectionList.appendChild(span);

      // Because we add a new span to the list, we need to reset the generatedRankedList in the middle of this process to:
      // 1. Check the limit and stop the ability to add new locations after 5 choices are made
      // 2. Set the hidden location field with the most up to date list of 5 choices for form submission
      generatedRankedList = Array.from(rankSelectionList.childNodes).map((node) => node.id);

      if (generatedRankedList.length > 5) {
        e.target.checked = false;
        rankSelectionList.removeChild(span);
        return alert("Only 5 locations can be chosen!");
      }

      // Add that choice to the hidden field value above the rankSelection div
      hiddenLocationField.setAttribute("value", Array.from(rankSelectionList.childNodes).map((node) => node.textContent).join("_"));
      localStorage.setItem("rankedList", generatedRankedList.toString());
    } else if (!e.target.checked) {
      // When a choice in unchecked, we'll want to:
      // 1. Grab the element in the sorted list
      // 2. Remove that element from the list
      // 3. Get an updated list of the rank selection with the choice now removed
      // 4. and 5. Update both the localStorage variable and the hiddenfield within the form to the updated ranked list
      const spanToRemove = document.getElementById(`span_${e.target.id}`);
      rankSelectionList.removeChild(spanToRemove);
      const generatedListChildNodes = Array.from(rankSelectionList.childNodes);
      hiddenLocationField.setAttribute("value", generatedListChildNodes.map((node) => node.textContent).join("_"));
      localStorage.setItem("rankedList", generatedListChildNodes.map((node) => node.id));
    }
  }

  // Here we're programmatically building the SVG that goes on the left of the location within the span that gets generated and added to the rank selection list
  generateHandleSVG () {
    const svg = document.createElement("sl-icon");
    svg.setAttribute("name", "bars-3");
    svg.classList.add("mr-1");

    return svg;
  }
}
