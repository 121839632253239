import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="hidden-field"
export default class extends Controller {
  static targets = [
    "toggleRejectWithdrawInput",
    "toggleCommunicationConfirmation",
    "toggleOnDeckInput",
    "triggerInput",
    "toggleTimingInput",
    "toggleStateInput",
    "toggleCityInput",
    "toggleNextStepText",
    "toggleTimingContainer",
    "nextStepText",
    "transitionButton",
  ];

  connect () {
    this.resetFields();

    const ele = document.getElementsByName("candidate_profile_submission[aasm_state]");
    for (let i = 0; i < ele.length; i++) {
      ele[i].checked = false;
    }
  }

  toggleTransitionCandidate (event) {
    this.resetFields();

    const selectedValue = event.target.value;
    document.getElementById("transitionState").value = selectedValue;

    switch (selectedValue) {
      case "Approve for onboarding":
        this.nextStepTextTarget.innerText = "Hired";
        this.toggleNextStepTextTarget.classList.remove("hidden");
        break;
      case "Begin video interview review":
        this.nextStepTextTarget.innerText = "Video Interview Completed";
        this.toggleNextStepTextTarget.classList.remove("hidden");
        break;
      case "Approve for first interview":
        this.nextStepTextTarget.innerText = "First Round Interview";
        this.toggleNextStepTextTarget.classList.remove("hidden");
        break;
      case "Approve for final interview":
        this.nextStepTextTarget.innerText = "Final Round Interview";
        this.toggleNextStepTextTarget.classList.remove("hidden");
        break;
      case "Approve for offer":
        this.nextStepTextTarget.innerText = "Offer Extended";
        this.toggleNextStepTextTarget.classList.remove("hidden");
        break;
      case "Complete onboarding":
        this.nextStepTextTarget.innerText = "Offer Accepted";
        this.toggleNextStepTextTarget.classList.remove("hidden");
        break;
      case "Approve for offer accepted":
        this.nextStepTextTarget.innerText = "Offer Accepted";
        this.toggleNextStepTextTarget.classList.remove("hidden");
        break;
      case "Reject":
        this.toggleRejectWithdrawInputTarget.parentElement.classList.remove("hidden");
        this.toggleCommunicationConfirmationTarget.classList.remove("hidden");
        document.getElementById("candidate_profile_submission_send_notice_true").checked = true;
        break;
      case "Decline offer":
      case "Withdraw":
        this.toggleRejectWithdrawInputTarget.parentElement.classList.remove("hidden");
        break;
      case "Place on deck":
        this.toggleOnDeckInputTarget.parentElement.classList.remove("hidden");
        this.toggleTimingContainerTarget.classList.remove("hidden");
        this.toggleStateInputTarget.parentElement.classList.remove("hidden");
        this.toggleCityInputTarget.parentElement.classList.remove("hidden");
        break;
    }

    this.transitionButtonTarget.classList.remove("hidden");
  }

  toggleOnDeckCommunicationOption () {
    if (this.toggleOnDeckInputTarget.value === "Position Closed") {
      this.toggleCommunicationConfirmationTarget.classList.remove("hidden");
      document.getElementById("candidate_profile_submission_send_notice_true").checked = true;
    } else {
      this.toggleCommunicationConfirmationTarget.classList.add("hidden");
    }
  }

  resetFields () {
    this.toggleTimingContainerTarget.classList.add("hidden");
    this.toggleNextStepTextTarget.classList.add("hidden");
    this.toggleRejectWithdrawInputTarget.parentElement.classList.add("hidden");
    this.toggleOnDeckInputTarget.parentElement.classList.add("hidden");
    this.toggleStateInputTarget.parentElement.classList.add("hidden");
    this.toggleCityInputTarget.parentElement.classList.add("hidden");
    this.toggleCommunicationConfirmationTarget.classList.add("hidden");
    this.transitionButtonTarget.classList.add("hidden");
    this.toggleTimingInputTarget.value = "";
    this.toggleStateInputTarget.value = "";
    this.toggleCityInputTarget.value = "";
    this.toggleOnDeckInputTarget.value = "";
  }
}
