import { Controller } from "@hotwired/stimulus";

// NOTE: We'll keep the commented out code in in case we add/activate more essay prompts later on.

// Connects to data-controller="essays-step"
export default class extends Controller {
  static targets = [
    "essayQuestionSubmissionAnswer0",
    "essayQuestionSubmissionAnswer1",
    // "essayQuestionSubmissionAnswer2",
    // "essayQuestionSubmissionAnswer3",
  ];

  connect () {
    this.setCountTextToCurrentLengthOnConnect();
  }

  calculateEssayLength (e) {
    // First Essay Question Submission
    if (e.target.getAttribute("data-essays-step-target") === "essayQuestionSubmissionAnswer0") {
      this.setCountTextToCurrentLengthOnInput(e, "essay_question_submission_count_0");
    }

    // Second Essay Question Submission
    if (e.target.getAttribute("data-essays-step-target") === "essayQuestionSubmissionAnswer1") {
      this.setCountTextToCurrentLengthOnInput(e, "essay_question_submission_count_1");
    }

    // // Third Essay Question Submission
    // if (e.target.getAttribute("data-essays-step-target") === "essayQuestionSubmissionAnswer2") {
    //   this.setCountTextToCurrentLengthOnInput(e, "essay_question_submission_count_2");
    // }

    // // Fourth Essay Question Submission
    // if (e.target.getAttribute("data-essays-step-target") === "essayQuestionSubmissionAnswer3") {
    //   this.setCountTextToCurrentLengthOnInput(e, "essay_question_submission_count_3");
    // }
  }

  setCountTextToCurrentLengthOnInput (event, id) {
    const textLength = event.target.value.length;
    const countText = document.getElementById(id);
    countText.textContent = textLength;
  }

  setCountTextToCurrentLengthOnConnect () {
    // First Essay Question Submission
    this.calculateAndAssignTextLength(this.essayQuestionSubmissionAnswer0Target, "essay_question_submission_count_0");

    // Second Essay Question Submission
    this.calculateAndAssignTextLength(this.essayQuestionSubmissionAnswer1Target, "essay_question_submission_count_1");

    // // Third Essay Question Submission
    // this.calculateAndAssignTextLength(this.essayQuestionSubmissionAnswer2Target, "essay_question_submission_count_2");

    // // Fourth Essay Question Submission
    // this.calculateAndAssignTextLength(this.essayQuestionSubmissionAnswer3Target, "essay_question_submission_count_3");
  }

  calculateAndAssignTextLength (answerTextareaTarget, countId) {
    const answerLength = answerTextareaTarget.value.length;
    const answerCountElement = document.getElementById(countId);
    answerCountElement.textContent = answerLength;
  }
}
