import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="opening-candidates-state-filter"
export default class extends Controller {
  static targets = [
    "currentStatus",
    "reviewDropdown",
  ];

  allActiveStates = "awaiting_video_interview reviewing_video_interview first_interview final_interview awaiting_offer_confirmation offer_accepted awaiting_onboarding_completion";

  connect () {
    this.resetAllFilters();
    const reviewButton = document.getElementById("reviewButton");

    if (this.currentStatusTarget.value !== null && this.currentStatusTarget.value !== "" && reviewButton) {
      if (this.currentStatusTarget.value === this.allActiveStates) {
        this.setActiveStateContext("all");
      } else {
        this.setActiveStateContext(this.currentStatusTarget.value);
      }
    } else {
      if (reviewButton) {
        this.setActiveStateContext("all");
      }
    }

    const dropdownEl = document.getElementById("reviewButtonDropdown");
    if (dropdownEl !== null) {
      document.addEventListener("click", event => {
        const isReviewDropdownButtonClicked = dropdownEl.contains(event.target);

        if (!isReviewDropdownButtonClicked) {
          this.reviewDropdownTarget.hidden = true;
        }
      });
    }
  }

  toggleReviewDropdown () {
    this.reviewDropdownTarget.hidden === true ? this.reviewDropdownTarget.hidden = false : this.reviewDropdownTarget.hidden = true;
  }

  resetAllFilters () {
    document.getElementById("query").value = "";
    document.getElementById("awaiting_video_interview").classList.remove(["border-blue-600"]);
    document.getElementById("reviewing_video_interview").classList.remove(["border-blue-600"]);
    document.getElementById("first_interview").classList.remove(["border-blue-600"]);
    document.getElementById("final_interview").classList.remove(["border-blue-600"]);
    document.getElementById("awaiting_offer_confirmation").classList.remove(["border-blue-600"]);
    document.getElementById("offer_accepted").classList.remove(["border-blue-600"]);
    document.getElementById("awaiting_onboarding_completion").classList.remove(["border-blue-600"]);
    document.getElementById("rejected on_deck withdrawn offer_declined").classList.remove(["border-blue-600"]);
    document.getElementById("all").classList.remove(["border-blue-600"]);
    if (document.getElementById("on_deck")) {
      document.getElementById("on_deck").classList.remove(["border-blue-600"]);
    }
  }

  setActiveStateContext (id) {
    document.getElementById(id).classList.add("border-blue-600");
    localStorage.setItem("candidateFilter", id);

    this.updateReviewButtonText();

    if (["awaiting_onboarding_completion", "rejected on_deck withdrawn offer_declined", "all", "on_deck"].includes(id)) {
      document.getElementById("reviewButton").classList.add("rounded-r-full");
      document.getElementById("reviewButtonDropdown").classList.add("hidden");
    } else {
      document.getElementById("reviewButton").classList.remove("rounded-r-full");
      document.getElementById("reviewButtonDropdown").classList.remove("hidden");
    }
  }

  activateCard (e) {
    let idToActivate;
    switch (e.target.nodeName) {
      case "BUTTON":
        idToActivate = e.target.id;
        break;
      case "SPAN":
        idToActivate = e.target.parentNode.id;
        break;
      default:
        break;
    }

    this.resetAllFilters();

    if (document.getElementById("reviewButton")) {
      this.setActiveStateContext(idToActivate);
    }
  }

  clearFilter () {
    this.resetAllFilters();
  }

  beginReview () {
    const checkedCandidates = Array.from(document.querySelectorAll(".select_candidates:checked"));
    const candidates = checkedCandidates.length > 0 ? checkedCandidates : Array.from(document.querySelectorAll(".candidate-profile-row"));

    localStorage.setItem("manualSelect", checkedCandidates.length > 0);
    const ids = candidates.map(function (el) {
      return el.getAttribute("data-candidate-id");
    });

    if (ids.length === 0) {
      alert("Please select at least one candidate to review");
      this.clearLocalFilters();
      return;
    }

    localStorage.setItem("candidateIds", ids);
    localStorage.setItem("openingId", window.location.href.split("/")[5]);

    if (localStorage.getItem("candidateFilter") && ids.length >= 1) {
      window.location.href = `${window.location.origin}/admin/submissions/${ids[0]}`;
    }
  }

  bulkAdvance () {
    const ids = this.getBulkActionCandidateIds();

    if (ids.length === 0) {
      alert("Please select at least one candidate to advance");
      this.clearLocalFilters();
      return;
    }

    document.getElementById("bulkAdvanceCandidateIds").value = ids;
  }

  bulkArchive (e) {
    const ids = this.getBulkActionCandidateIds();

    if (ids.length === 0) {
      alert("Please select at least one candidate to archive");
      this.clearLocalFilters();
      return;
    }

    const archiveEvent = e.target.innerText;

    switch (archiveEvent) {
      case "Reject":
        document.getElementById("bulkRejectCandidateIds").value = ids;
        break;
      case "Withdraw":
        document.getElementById("bulkWithdrawCandidateIds").value = ids;
        break;
      case "Place on deck":
        document.getElementById("bulkPlaceOnDeckCandidateIds").value = ids;
        break;
    }
  }

  getBulkActionCandidateIds () {
    this.toggleReviewDropdown();
    const checkedCandidates = Array.from(document.querySelectorAll(".select_candidates:checked"));

    const ids = checkedCandidates.map(function (el) {
      return el.getAttribute("data-candidate-id");
    });

    return ids;
  }

  checkAll (e) {
    const checkboxes = document.querySelectorAll(".select_candidates");
    const checked = e.target.checked;
    checkboxes.forEach((c) => { c.checked = checked; });
    this.updateReviewButtonText();
  }

  checkCandidate () {
    const checkedCandidates = document.querySelectorAll(".select_candidates:checked");
    const allChecked = checkedCandidates.length === document.querySelectorAll(".select_candidates").length;
    document.querySelector(".selectall-checkbox").checked = allChecked;
    this.updateReviewButtonText();
  }

  updateReviewButtonText () {
    const filter = localStorage.getItem("candidateFilter");
    const checked = document.querySelectorAll(".select_candidates:checked").length;

    let reviewString = "";
    if (checked > 0) {
      reviewString = "Review selected";
    } else if (filter === "all") {
      reviewString = "Review all";
    } else {
      reviewString = "Review status";
    }

    document.getElementById("reviewButton").innerText = reviewString;
  }

  clearLocalFilters () {
    localStorage.setItem("candidateFilter", null);
    localStorage.setItem("candidateIds", null);
    localStorage.setItem("openingId", null);
  }
}
