import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="matching-preference-step--immediate-placement"
export default class extends Controller {
  static targets = [
    "hideLocationSelectionIcon",
    "showLocationSelectionIcon",
    "locationSection",

    "hideMatchNotificationSelectionIcon",
    "showMatchNotificationSelectionIcon",
    "matchNotificationSelection",

    "followUpMoveChoiceLabel",
    "followUpMoveChoiceCityInput",
    "followUpMoveChoiceStateDropdown",
  ];

  static values = { region: String };

  connect () {
    const existingRegionValueIsInList = Array.from(document.getElementsByClassName("location_choice")).map((input) => input.value).includes(this.regionValue);
    if (this.regionValue !== "" && existingRegionValueIsInList) {
      Array.from(document.getElementsByClassName("location_choice")).filter((input) => input.value == this.regionValue)[0].checked = true // eslint-disable-line
    }
    this.showLocationSelection();
    this.hideMatchNotificationSelection();
  }

  triggerLocationMatch () {
    if (this.showLocationSelectionIconTarget.hidden) {
      this.hideLocationSelection();
      this.uncheckAllLocations();
      this.showMatchNotificationSelection();
    } else {
      this.showLocationSelection();
      this.hideMatchNotificationSelection();
    }
  }

  triggerFollowupSelection () {
    if (this.showMatchNotificationSelectionIconTarget.hidden) {
      this.showLocationSelection();
      this.hideMatchNotificationSelection();
    } else {
      this.hideLocationSelection();
      this.uncheckAllLocations();
      this.showMatchNotificationSelection();
    }
  }

  showLocationSelection () {
    if (this.hasShowLocationSelectionIconTarget & this.hasHideLocationSelectionIconTarget) {
      this.hideLocationSelectionIconTarget.hidden = false;
      this.showLocationSelectionIconTarget.hidden = true;
      this.locationSectionTarget.hidden = false;
      document.getElementById("matching_preference_location_followup_my_desired_location_matches_my_current_zipcode").checked = false;
      document.getElementById("matching_preference_location_followup_i_have_plans_to_move_to_a_location_that_does_not_match_my_current_zip_code").checked = false;
      this.setDisabledFollowUpChoicesContext();
    }
  }

  hideLocationSelection () {
    this.hideLocationSelectionIconTarget.hidden = true;
    this.showLocationSelectionIconTarget.hidden = false;
    this.locationSectionTarget.hidden = true;
  }

  showMatchNotificationSelection () {
    this.hideMatchNotificationSelectionIconTarget.hidden = false;
    this.showMatchNotificationSelectionIconTarget.hidden = true;
    this.matchNotificationSelectionTarget.hidden = false;
  }

  hideMatchNotificationSelection () {
    if (this.hasHideMatchNotificationSelectionIconTarget) {
      this.hideMatchNotificationSelectionIconTarget.hidden = true;
      this.showMatchNotificationSelectionIconTarget.hidden = false;
      this.matchNotificationSelectionTarget.hidden = true;
    }
  }

  toggleFollowUpMoveChoices (e) {
    switch (e.target.value) {
      case "My desired location matches my current zipcode":
        this.setDisabledFollowUpChoicesContext();
        break;
      case "I have plans to move to a location that does not match my current zip code":
        this.setEnabledFollowUpChoicesContext();
        break;
    }
  }

  setDisabledFollowUpChoicesContext () {
    this.followUpMoveChoiceLabelTarget.classList.add("text-gray-400");
    this.followUpMoveChoiceCityInputTarget.disabled = true;
    this.followUpMoveChoiceCityInputTarget.value = "";
    this.followUpMoveChoiceStateDropdownTarget.disabled = true;
  }

  setEnabledFollowUpChoicesContext () {
    this.followUpMoveChoiceLabelTarget.classList.remove("text-gray-400");
    this.followUpMoveChoiceCityInputTarget.disabled = false;
    this.followUpMoveChoiceStateDropdownTarget.disabled = false;
  }

  highlightSingleLocationInput (e) {
    this.regionValue = e.target.value;
    switch (e.target.name) {
      case "matching_preference[new_england]":
        this.uncheckOtherLocations("new_england");
        break;
      case "matching_preference[middle_atlantic]":
        this.uncheckOtherLocations("middle_atlantic");
        break;
      case "matching_preference[south]":
        this.uncheckOtherLocations("south");
        break;
      case "matching_preference[west]":
        this.uncheckOtherLocations("west");
        break;
      case "matching_preference[midwest]":
        this.uncheckOtherLocations("midwest");
        break;
      case "matching_preference[southwest]":
        this.uncheckOtherLocations("southwest");
        break;
    }
  }

  uncheckAllLocations () {
    Array.from(document.getElementsByClassName("location_choice")).forEach(function (input) { input.checked = false; });
  }

  uncheckOtherLocations (region) {
    Array.from(document.getElementsByClassName("location_choice")).filter((input) => input.name !== `matching_preference[${region}]`).forEach(function (input) { input.checked = false; });
  }
}
