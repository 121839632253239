import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="read-more"
export default class extends Controller {
  toggle (e) {
    const textId = e.target.id.replace("read_", "");
    if (e.target.innerHTML === "Show more") {
      document.getElementById("text_".concat(textId)).classList.remove("line-clamp-3");
      e.target.innerHTML = "Show less";
    } else {
      document.getElementById("text_".concat(textId)).classList.add("line-clamp-3");
      e.target.innerHTML = "Show more";
    }
  }
}
