import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="matching-preference-step--deferred-year"
export default class extends Controller {
  static targets = [
    "preferredDeferredLocationSelection",
  ];

  connect () {
    const isDesiredLocationCommutableFalseInput = document.getElementById("matching_preference_is_desired_location_commutable_false");
    const isDesiredLocationCommutableTrueInput = document.getElementById("matching_preference_is_desired_location_commutable_true");
    this.applyInactiveStateContext(isDesiredLocationCommutableFalseInput.parentNode.parentNode);
    this.applyInactiveStateContext(isDesiredLocationCommutableTrueInput.parentNode.parentNode);

    if (isDesiredLocationCommutableFalseInput.checked) {
      this.togglePreferredDeferredLocationSelection(false);
      this.applyActiveStateContext(isDesiredLocationCommutableFalseInput.parentNode.parentNode);
      document.getElementById("matching_preference_location_followup_city").value = "";
    } else if (isDesiredLocationCommutableTrueInput.checked) {
      this.applyActiveStateContext(isDesiredLocationCommutableTrueInput.parentNode.parentNode);
    }
  }

  highlightChoice (e) {
    switch (e.target.id) {
      case "matching_preference_is_desired_location_commutable_false":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("matching_preference_is_desired_location_commutable_true").parentNode.parentNode);
        this.togglePreferredDeferredLocationSelection(false);
        break;
      case "matching_preference_is_desired_location_commutable_true":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("matching_preference_is_desired_location_commutable_false").parentNode.parentNode);
        this.togglePreferredDeferredLocationSelection(true);
        break;
      default:
        break;
    }
  }

  togglePreferredDeferredLocationSelection (isCommutable) {
    if (isCommutable) {
      this.preferredDeferredLocationSelectionTarget.classList.add("hidden");
    } else {
      this.preferredDeferredLocationSelectionTarget.classList.remove("hidden");
    }
    document.getElementById("matching_preference_location_followup_city").value = "";
  }

  applyActiveStateContext (el) {
    el.classList.add("bg-blue-100");
    el.classList.add("text-blue-600");
  }

  applyInactiveStateContext (el) {
    el.classList.remove("bg-blue-100");
    el.classList.remove("text-blue-600");
  }
}
