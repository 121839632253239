import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="administrative-info-step"
export default class extends Controller {
  connect () {
    this.resetAllRadioButtons();
    this.checkChoiceInputs();
  }

  highlightChoice (e) {
    switch (e.target.parentNode.htmlFor) {
      case "administrative_info_compensation_accepted_false":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_compensation_accepted_true").parentNode.parentNode);
        break;
      case "administrative_info_compensation_accepted_true":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_compensation_accepted_false").parentNode.parentNode);
        break;
      case "administrative_info_business_interest_disclosure_yes":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_business_interest_disclosure_no").parentNode.parentNode);
        break;
      case "administrative_info_business_interest_disclosure_no":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_business_interest_disclosure_yes").parentNode.parentNode);
        break;
      case "administrative_info_us_work_authorization_true":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_us_work_authorization_false").parentNode.parentNode);
        break;
      case "administrative_info_us_work_authorization_false":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_us_work_authorization_true").parentNode.parentNode);
        break;
      case "administrative_info_require_employment_sponsorship_true":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_require_employment_sponsorship_false").parentNode.parentNode);
        break;
      case "administrative_info_require_employment_sponsorship_false":
        this.applyActiveStateContext(e.target.parentNode.parentNode);
        this.applyInactiveStateContext(document.getElementById("administrative_info_require_employment_sponsorship_true").parentNode.parentNode);
        break;
      default:
        break;
    }
  }

  resetAllRadioButtons () {
    document.querySelectorAll(".admin-info-choice").forEach(el => {
      this.applyInactiveStateContext(el);
    });
  }

  checkChoiceInputs () {
    // Compensation
    const compensationTrue = document.getElementById("administrative_info_compensation_accepted_true");
    const compensationFalse = document.getElementById("administrative_info_compensation_accepted_false");

    if (compensationTrue.checked) {
      this.applyActiveStateContext(compensationTrue.parentNode.parentNode);
      this.applyInactiveStateContext(compensationFalse.parentNode.parentNode);
    }

    if (compensationFalse.checked) {
      this.applyActiveStateContext(compensationFalse.parentNode.parentNode);
      this.applyInactiveStateContext(compensationTrue.parentNode.parentNode);
    }

    // Business Interest Disclosure
    const disclosureYes = document.getElementById("administrative_info_business_interest_disclosure_yes");
    const disclosureNo = document.getElementById("administrative_info_business_interest_disclosure_no");

    if (disclosureYes.checked) {
      this.applyActiveStateContext(disclosureYes.parentNode.parentNode);
      this.applyInactiveStateContext(disclosureNo.parentNode.parentNode);
    }

    if (disclosureNo.checked) {
      this.applyActiveStateContext(disclosureNo.parentNode.parentNode);
      this.applyInactiveStateContext(disclosureYes.parentNode.parentNode);
    }

    // US Work Authorization
    const workAuthTrue = document.getElementById("administrative_info_us_work_authorization_true");
    const workAuthFalse = document.getElementById("administrative_info_us_work_authorization_false");

    if (workAuthTrue.checked) {
      this.applyActiveStateContext(workAuthTrue.parentNode.parentNode);
      this.applyInactiveStateContext(workAuthFalse.parentNode.parentNode);
    }

    if (workAuthFalse.checked) {
      this.applyActiveStateContext(workAuthFalse.parentNode.parentNode);
      this.applyInactiveStateContext(workAuthTrue.parentNode.parentNode);
    }

    // Require Employment Sponsorship
    const employmentSponsorshipTrue = document.getElementById("administrative_info_require_employment_sponsorship_true");
    const employmentSponsorshipFalse = document.getElementById("administrative_info_require_employment_sponsorship_false");

    if (employmentSponsorshipTrue.checked) {
      this.applyActiveStateContext(employmentSponsorshipTrue.parentNode.parentNode);
      this.applyInactiveStateContext(employmentSponsorshipFalse.parentNode.parentNode);
    }

    if (employmentSponsorshipFalse.checked) {
      this.applyActiveStateContext(employmentSponsorshipFalse.parentNode.parentNode);
      this.applyInactiveStateContext(employmentSponsorshipTrue.parentNode.parentNode);
    }
  }

  applyActiveStateContext (el) {
    el.classList.add("bg-blue-100");
    el.classList.add("rounded-md");
    el.classList.add("text-blue-600");
  }

  applyInactiveStateContext (el) {
    el.classList.remove("bg-blue-100");
    el.classList.add("rounded-md");
    el.classList.remove("text-blue-600");
  }
}
