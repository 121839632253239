import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="candidate-profile-note"
export default class extends Controller {
  static targets = [
    "notesInput",
    "form",
  ];

  clearNote () {
    this.notesInputTarget.value = "";
  }

  submitNote () {
    this.formTarget.requestSubmit();
    this.clearNote();
  }

  openNotes () {
    const drawer = document.querySelector(".drawer");
    drawer.show();
  }
}
