import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="archive-modal"
export default class extends Controller {
  static targets = [
    "archiveTo",
    "rejectWithdrawReason",
    "sendNotice",
    "onDeckFields",
    "onDeckReason",
  ];

  hideModal () {
    this.element.parentElement.removeAttribute("src"); // it might be nice to also remove the modal SRC
    this.element.remove();
  }

  // Hide modal on successful form submission
  // Action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd (e) {
    if (e.detail.success) {
      this.hideModal();
    }
  }

  connect () {
    const archiveEvent = this.archiveToTarget.value;

    switch (archiveEvent) {
      case "Reject":
        this.rejectWithdrawReasonTarget.parentElement.classList.remove("hidden");
        this.sendNoticeTarget.classList.remove("hidden");
        document.getElementById("candidate_profile_submission_send_notice_true").checked = true;
        break;
      // Decline offer does the same as withdraw so both will do what is under withdraw
      case "Decline offer":
      case "Withdraw":
        this.rejectWithdrawReasonTarget.parentElement.classList.remove("hidden");
        break;
      case "Place on deck":
        this.onDeckFieldsTarget.classList.remove("hidden");
        break;
    }
  }

  toggleOnDeckSendNotice () {
    if (this.onDeckReasonTarget.value === "Position Closed") {
      this.sendNoticeTarget.classList.remove("hidden");
      document.getElementById("candidate_profile_submission_send_notice_true").checked = true;
    } else {
      this.sendNoticeTarget.classList.add("hidden");
    }
  }
}
