import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copyButton"];

  copy (e) {
    e.preventDefault();
    if (!this.hasCopyButtonTarget) return;
    const originalText = e.target.innerHTML;
    navigator.clipboard.writeText(e.target.attributes["data-clipboard-text"].value);
    e.target.innerHTML = "Copied!";

    setTimeout(() => {
      e.target.innerHTML = originalText;
    }, 2000);
  }
}
