import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="candidate-profile-score"
export default class extends Controller {
  static targets = ["notesInput", "form"];

  openEvaluation ({ params: { step } }) {
    document.getElementById("evaluation-title").innerHTML = step.split(" ").map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    }).join(" ");

    document.getElementById("evaluation_title").value = step;
    document.getElementById("evaluation_feedback").value = "";
    document.querySelector(".evaluation").show();
  }
}
