import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="candidate-profile-step"
export default class extends Controller {
  connect () {
    this.resetReadinessChoices();

    const timingChoices = document.querySelectorAll(".readiness-option");

    timingChoices.forEach((tc) => {
      tc.addEventListener("click", (e) => {
        switch (e.target.tagName) {
          case "DIV":
            document.querySelectorAll(".timingChoiceHeader").forEach((el) => el.classList.remove("text-blue-600"));
            Array(document.querySelectorAll(".readiness-option")).filter(el => el.target !== e.target)[0].forEach((tc) => {
              this.applyInactiveStateContext(tc);
            });
            e.target.firstChild.firstChild.checked = true;
            this.applyActiveStateContext(e.target);
            break;
          case "INPUT":
            this.resetReadinessChoices();
            e.target.checked = true;
            this.applyActiveStateContext(e.target.parentNode.parentNode);
            break;
          default:
            break;
        }
      });
    });
  }

  resetReadinessChoices () {
    const timingChoices = document.querySelectorAll(".readiness-option");

    timingChoices.forEach((tc) => {
      this.applyInactiveStateContext(tc);
    });

    document.querySelectorAll(".timingChoiceHeader").forEach((el) => el.classList.remove("text-blue-600"));

    this.checkReadinessInputs();
  }

  checkReadinessInputs () {
    const immediateTimingChoice = document.getElementById("candidate_profile_candidate_profile_submissions_attributes_0_placement_status_immediate");
    if (immediateTimingChoice.checked) {
      const choiceWrapper = immediateTimingChoice.parentNode.parentNode;
      this.applyActiveStateContext(choiceWrapper);
      document.getElementById("immediateTimingChoiceHeader").classList.add("text-blue-600");
    }

    if (document.getElementById("candidate_profile_candidate_profile_submissions_attributes_0_placement_status_mba") !== null) {
      const mbaTimingChoice = document.getElementById("candidate_profile_candidate_profile_submissions_attributes_0_placement_status_mba");
      if (mbaTimingChoice.checked) {
        const choiceWrapper = mbaTimingChoice.parentNode.parentNode;
        this.applyActiveStateContext(choiceWrapper);
        document.getElementById("mbaTimingChoiceHeader").classList.add("text-blue-600");
      }
    }

    if (document.getElementById("candidate_profile_candidate_profile_submissions_attributes_0_placement_status_mba_intern") !== null) {
      const mbaInternTimingChoice = document.getElementById("candidate_profile_candidate_profile_submissions_attributes_0_placement_status_mba_intern");
      if (mbaInternTimingChoice.checked) {
        const choiceWrapper = mbaInternTimingChoice.parentNode.parentNode;
        this.applyActiveStateContext(choiceWrapper);
        document.getElementById("mbaInternTimingChoiceHeader").classList.add("text-blue-600");
      }
    }

    const deferredYearTimingChoice = document.getElementById("candidate_profile_candidate_profile_submissions_attributes_0_placement_status_deferred_year");
    if (deferredYearTimingChoice.checked) {
      const choiceWrapper = deferredYearTimingChoice.parentNode.parentNode;
      this.applyActiveStateContext(choiceWrapper);
      document.getElementById("deferredYearTimingChoiceHeader").classList.add("text-blue-600");
    }
  }

  applyActiveStateContext (el) {
    el.classList.remove("border-gray-300");
    el.classList.add("border-blue-600");
    el.classList.add("bg-blue-100");
  }

  applyInactiveStateContext (el) {
    el.classList.add("border-gray-300");
    el.classList.remove("border-blue-600");
    el.classList.remove("bg-blue-100");
  }
}
