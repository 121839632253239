import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="candidate-profile-note-new"
export default class extends Controller {
  connect () {
    document.querySelector("#notes").scrollIntoView(false);

    // Increase count on notes button
    const notesButtonText = document.querySelector("#notesButton").innerHTML;
    const oldCount = notesButtonText.substring(notesButtonText.lastIndexOf("(") + 1, notesButtonText.lastIndexOf(")"));
    const newCount = parseInt(oldCount) + 1;
    document.querySelector("#notesButton").innerHTML = notesButtonText.replace(oldCount, newCount);
  }
}
