import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="kebab-dropdown"
export default class extends Controller {
  static targets = ["dropdown"];

  connect () {
    const dropdownEl = document.getElementById("kebab-dropdown-button");
    if (dropdownEl !== null) {
      document.addEventListener("click", event => {
        const isKebabDropdownButtonClicked = dropdownEl.contains(event.target);

        if (!isKebabDropdownButtonClicked) {
          this.dropdownTarget.hidden = true;
        }
      });
    }
  }

  toggleDropdown () {
    this.dropdownTarget.hidden === true ? this.dropdownTarget.hidden = false : this.dropdownTarget.hidden = true;
  }
}
