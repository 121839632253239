import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="submission-view"
export default class extends Controller {
  static targets = [
    "advanceButton",
    "advanceOption",
    "revertButton",
    "revertOption",
    "rejectButton",
    "rejectOption",
    "withdrawButton",
    "withdrawOption",
    "onDeckButton",
    "onDeckOption",
    "reassignButton",
    "reassignOption",
  ];

  static values = { state: String, previousState: String };

  disableFields () {
    if (this.previousStateValue === "" || this.previousStateValue === "awaiting_application") {
      this.revertButtonTarget.setAttribute("disabled", "");
      this.revertOptionTarget.setAttribute("disabled", "");
    }

    switch (this.stateValue) {
      case "awaiting_video_interview":
        this.revertButtonTarget.setAttribute("disabled", "");
        this.revertOptionTarget.setAttribute("disabled", "");
        break;
      case "reviewing_video_interview":
        break;
      case "first_interview":
        break;
      case "final_interview":
        break;
      case "awaiting_offer_confirmation":
        this.rejectButtonTarget.setAttribute("disabled", "");
        this.rejectOptionTarget.setAttribute("disabled", "");
        this.withdrawButtonTarget.setAttribute("disabled", "");
        this.withdrawOptionTarget.setAttribute("disabled", "");
        this.reassignButtonTarget.setAttribute("disabled", "");
        this.reassignOptionTarget.setAttribute("disabled", "");
        break;
      case "awaiting_onboarding_completion":
        this.advanceButtonTarget.setAttribute("disabled", "");
        this.advanceOptionTarget.setAttribute("disabled", "");
        break;
      case "onboarding_completed":
        this.advanceButtonTarget.setAttribute("disabled", "");
        this.advanceOptionTarget.setAttribute("disabled", "");
        break;
      case "offer_accepted":
        break;
      case "on_deck":
        this.advanceButtonTarget.setAttribute("disabled", "");
        this.advanceOptionTarget.setAttribute("disabled", "");
        this.onDeckButtonTarget.setAttribute("disabled", "");
        this.onDeckOptionTarget.setAttribute("disabled", "");
        break;
      case "rejected":
        this.advanceButtonTarget.setAttribute("disabled", "");
        this.advanceOptionTarget.setAttribute("disabled", "");
        this.rejectButtonTarget.setAttribute("disabled", "");
        this.rejectOptionTarget.setAttribute("disabled", "");
        this.withdrawButtonTarget.setAttribute("disabled", "");
        this.withdrawOptionTarget.setAttribute("disabled", "");
        this.onDeckButtonTarget.setAttribute("disabled", "");
        this.onDeckOptionTarget.setAttribute("disabled", "");
        this.reassignButtonTarget.setAttribute("disabled", "");
        this.reassignOptionTarget.setAttribute("disabled", "");
        break;
      case "withdrawn":
        this.advanceButtonTarget.setAttribute("disabled", "");
        this.advanceOptionTarget.setAttribute("disabled", "");
        this.rejectButtonTarget.setAttribute("disabled", "");
        this.rejectOptionTarget.setAttribute("disabled", "");
        this.withdrawButtonTarget.setAttribute("disabled", "");
        this.withdrawOptionTarget.setAttribute("disabled", "");
        this.onDeckButtonTarget.setAttribute("disabled", "");
        this.onDeckOptionTarget.setAttribute("disabled", "");
        this.reassignButtonTarget.setAttribute("disabled", "");
        this.reassignOptionTarget.setAttribute("disabled", "");
        break;
      case "offer_declined":
        this.advanceButtonTarget.setAttribute("disabled", "");
        this.advanceOptionTarget.setAttribute("disabled", "");
        this.rejectButtonTarget.setAttribute("disabled", "");
        this.rejectOptionTarget.setAttribute("disabled", "");
        this.withdrawButtonTarget.setAttribute("disabled", "");
        this.withdrawOptionTarget.setAttribute("disabled", "");
        this.reassignButtonTarget.setAttribute("disabled", "");
        this.reassignOptionTarget.setAttribute("disabled", "");
        break;
      default:
        break;
    }
  }
}
