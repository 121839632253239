import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="review-candidates-controller"
export default class extends Controller {
  currentCandidateId;
  reviewIndex;

  connect () {
    if (localStorage.getItem("candidateIds")) {
      const candidateIds = localStorage.getItem("candidateIds").split(",");
      this.currentCandidateId = window.location.href.split("/")[6];
      this.reviewIndex = candidateIds.indexOf(this.currentCandidateId);

      if (candidateIds.includes(this.currentCandidateId)) {
        document.getElementById("reviewNavbar").classList.remove("hidden");
      }

      if ((candidateIds.length - 1) <= this.reviewIndex) {
        document.getElementById("rightReviewLink").classList.add("hidden");
      }

      if ((this.reviewIndex === 0)) {
        document.getElementById("leftReviewLink").classList.add("hidden");
      }

      if (document.getElementById("reviewCounter")) {
        document.getElementById("reviewCounter").innerHTML = `${this.reviewIndex + 1} of ${candidateIds.length}`;
      }
    }

    if (localStorage.getItem("candidateFilter")) {
      let humanizedFilter = localStorage.getItem("manualSelect") === "true"
        ? this.humanizeSelection(localStorage.getItem("candidateIds").split(",").length)
        : this.humanizeFilter(localStorage.getItem("candidateFilter"));

      switch (humanizedFilter) {
        case "Reviewing video interview":
          humanizedFilter = "Video Interview Completed";
          break;
        case "Awaiting offer confirmation":
          humanizedFilter = "Offer extended";
          break;
        case "Awaiting onboarding completion":
          humanizedFilter = "Hired";
          break;
        case "Rejected on deck withdrawn offer declined":
          humanizedFilter = "Archive";
          break;
        default:
          break;
      }

      document.getElementById("nowViewing").innerHTML = `Now Viewing: ${humanizedFilter}`;
    }
  }

  humanizeSelection (count) {
    return count === 1 ? "1 Selected Candidate" : `${count} Selected Candidates`;
  }

  humanizeFilter (filter) {
    return filter.replace(/_/g, " ")
      .trim()
      .replace(/\b[A-Z][a-z]+\b/g, function (word) {
        return word.toLowerCase();
      })
      .replace(/^[a-z]/g, function (first) {
        return first.toUpperCase();
      });
  }

  exitReview () {
    const openingId = localStorage.getItem("openingId");
    this.clearLocalFilters();
    window.location.href = `${window.location.origin}/admin/openings/${openingId}`;
  }

  navigateRight () {
    const candidateIds = localStorage.getItem("candidateIds").split(",");
    window.location.href = `${window.location.origin}/admin/submissions/${candidateIds[this.reviewIndex + 1]}`;
  }

  navigateLeft () {
    const candidateIds = localStorage.getItem("candidateIds").split(",");
    const index = parseInt(localStorage.getItem("reviewIndex"));

    localStorage.setItem("reviewIndex", index - 1);
    window.location.href = `${window.location.origin}/admin/submissions/${candidateIds[this.reviewIndex - 1]}`;
  }

  clearLocalFilters () {
    localStorage.setItem("candidateFilter", null);
    localStorage.setItem("candidateIds", null);
    localStorage.setItem("openingId", null);
  }
}
