import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="type-to-confirm"
export default class extends Controller {
  static targets = ["confirmationInput", "confirmationValue", "confirmButton"];

  checkConfirmationInput () {
    if (this.confirmationInputTarget.value === this.confirmationValueTarget.value) {
      this.confirmButtonTarget.disabled = false;
    } else {
      this.confirmButtonTarget.disabled = true;
    }
  }
}
